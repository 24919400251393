export default {
  pearegrine: [
    'devicon-javascript-plain',
    'devicon-html5-plain',
    'devicon-css3-plain',
    'devicon-react-original',
    'devicon-nodejs-plain',
    'devicon-express-original',
    'devicon-postgresql-plain',
  ],
  molly: [
    'devicon-javascript-plain',
    'devicon-html5-plain',
    'devicon-css3-plain',
    'devicon-react-original',
    'devicon-nodejs-plain',
    'devicon-express-original',
    'devicon-postgresql-plain',
  ],
  quoter: [
    'devicon-javascript-plain',
    'devicon-html5-plain',
    'devicon-css3-plain',
    'devicon-react-original',
    'devicon-nodejs-plain',
    'devicon-express-original',
    'devicon-mocha-plain',
    'devicon-postgresql-plain',
  ],
  miMood: [
    'devicon-javascript-plain',
    'devicon-html5-plain',
    'devicon-css3-plain',
    'devicon-react-original',
    'devicon-nodejs-plain',
    'devicon-express-original',
    'devicon-mocha-plain',
    'devicon-postgresql-plain',
    'devicon-amazonwebservices-original',
  ],
  bookmarks: [
    'devicon-javascript-plain',
    'devicon-html5-plain',
    'devicon-css3-plain',
    'devicon-jquery-plain-wordmark',
  ]
}